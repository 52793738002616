<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Liquidaciones</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tep</li>
                  <li class="breadcrumb-item active">Liquidaciones</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <button type="button" class="btn btn-primary" @click="create()" v-if="$store.getters.can(
      'tep.liquidacionesNacionales.create'
    )
      ">
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="
                    table table-bordered table-striped table-hover table-sm
                  ">
                  <thead>
                    <tr>
                      <!---Inicio Input Filtro Radicado---->
                      <th>#</th>
                      <th>
                        Empresa
                        <v-select :class="$store.getters.getDarkMode ? 'dark-vselect' : ''
      " v-model="empresa" placeholder="Seleccione Empresa..." label="razon_social"
                          class="form-control form-control-sm p-0" :options="listasForms.empresas" :filterable="false"
                          @search="buscarEmpresas" @input="selectEmpresa()">
                        </v-select>
                      </th>
                      <th>
                        Fecha Inicial
                        <input type="date" class="form-control form-control-sm" v-model="filtros.fecha_ini"
                          @input="getIndex()" />
                      </th>
                      <th>
                        Fecha Final
                        <input type="date" class="form-control form-control-sm" v-model="filtros.fecha_fin"
                          @input="getIndex()" />
                      </th>
                      <th>
                        Contrato
                        <v-select :class="$store.getters.getDarkMode ? 'dark-vselect' : ''" v-model="contratos"
                          placeholder="Todos..." label="descripcion" class="form-control form-control-sm p-0"
                          :filterable="false" :options="listasForms.contratos" @search="buscarContratos"
                          @input="selectContrato()"></v-select>
                      </th>
                      <th>
                        Estado
                        <select id="estado" v-model="filtros.estado" class="form-control form-control-sm"
                          @change="getIndex()">
                          <option value="">Seleccione...</option>
                          <option v-for="estado in listasForms.estados" :key="estado.numeracion"
                            :value="estado.numeracion">
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>
                        Estado Proceso
                        <select id="estado" v-model="filtros.estado_proceso" class="form-control form-control-sm"
                          @change="getIndex()">
                          <option value="">Seleccione...</option>
                          <option v-for="estado in listasForms.estadosProceso" :key="estado.numeracion"
                            :value="estado.numeracion">
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th style="text-align: center"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(liqui, index) in liquidaciones.data" :key="index">
                      <td>{{ liqui.id }}</td>
                      <td>
                        {{ liqui.empresa ? liqui.empresa.razon_social : "" }}
                      </td>
                      <td>{{ liqui.fecha_ini }}</td>
                      <td>{{ liqui.fecha_fin }}</td>
                      <td class="text-center">
                        <div v-if="liqui.empresa">
                          <div v-for="contratos in liqui.empresa.tep_contratos_nacionales" :key="contratos.id">
                            <small>{{ contratos.no_contrato }}</small>
                          </div>
                        </div>
                      </td>
                      <td class="text-center">
                        <span class="badge" :class="liqui.estado == 1
      ? 'badge-warning'
      : 'badge-success'
      ">
                          {{ liqui.nEstado }}
                        </span>
                      </td>

                      <td class="text-center">
                        <span class="badge" :class="liqui.estado_proceso == 1
      ? 'bg-info'
      : liqui.estado_proceso == 2
        ? 'bg-warning'
        : liqui.estado_proceso == 3
          ? 'bg-olive'
          : liqui.estado_proceso == 4
            ? 'bg-navy'
            : liqui.estado_proceso == 5
              ? 'bg-success'
              : liqui.estado_proceso == 6
                ? 'bg-lime'
                : 'bg-secondary'
      ">
                          {{ liqui.nEstadoProceso }}
                        </span>
                      </td>
                      <td class="text-right" style="width: 50px">
                        <div class="btn-group float-right">
                          <!-- Botón Editar -->
                          <button type="button" class="btn bg-navy btn-sm" @click="edit(liqui)">
                            <i class="fas fa-edit"></i>
                          </button>
                          <!-- Botón Firmas -->
                          <button type="button" class="btn bg-teal btn-sm" data-toggle="modal"
                            data-target="#Modal_firmas" @click="getDataFirmas(liqui)" v-if="(
      $store.getters.can('tep.liquidacionesNacionales.firmaAdministradorContrato')
      || $store.getters.can('tep.liquidacionesNacionales.firmaInterventor')
      || $store.getters.can('tep.liquidacionesNacionales.firmaRepresentanteTransportadora')
    )
      && liqui.estado_proceso == 6
      && liqui.estado == 2
      ">
                            <i class="fas fa-signature"></i>
                          </button>
                          <!-- Botón registro de pago -->
                          <!-- <button
                            type="button"
                            class="btn bg-lightblue btn-sm"
                            data-toggle="modal"
                            data-target="#Modal_pago"
                            @click="getDataPago(liqui)"
                            v-if="
                              $store.getters.can(
                                'tep.liquidacionesNacionales.registrarPago'
                              ) && liqui.estado_proceso == 4
                            "
                          >
                            <i class="fas fa-money-bill-wave"></i>
                          </button> -->
                          <!-- Botón Ticket -->
                          <button type="button" class="btn bg-indigo btn-sm" @click="printTicket(liqui)" v-if="$store.getters.can(
      'tep.liquidacionesNacionales.ticket'
    ) &&
      (liqui.estado_proceso == 3 ||
        liqui.estado_proceso == 4) &&
      liqui.estado == 2
      ">
                            <i class="fas fa-print"></i>
                          </button>
                          <!-- Botón Excel -->
                          <button type="button" class="btn btn-success" @click="exportExcel(liqui.id)" v-if="$store.getters.can(
      'tep.liquidacionesNacionales.descargarExcel'
    )
      ">
                            <i class="far fa-file-excel"></i>
                          </button>
                          <button type="button" class="btn bg-warning btn-sm" data-toggle="modal"
                            data-target="#modal-form-crear-planilla" @click="llenar_modal(liqui)" v-if="$store.getters.can(
      'tep.liquidacionesNacionales.planilla'
    ) && liqui.estado == 2
      ">
                            <i class="fas fa-paste"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="liquidaciones.total">
                  <p>
                    Mostrando del <b>{{ liquidaciones.from }}</b> al
                    <b>{{ liquidaciones.to }}</b> de un total:
                    <b>{{ liquidaciones.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination :data="liquidaciones" @pagination-change-page="getIndex" :limit="5" class="float-right">
                </pagination>
              </div>
            </div>
          </div>
        </section>

        <!-- Modal de firmas -->
        <div class="modal fade" id="Modal_firmas">
          <div class="modal-dialog modal-xl">
            <div class="modal-content">
              <div class="modal-header bg-frontera-top-left pt-2 pb-2">
                <h4 class="modal-title text-white">{{ modalTitle }}</h4>
                <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close" id="close-modal"
                  ref="closeModalFirmas">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <!--- Firma Interventor--->
                  <div class="form-group col-md-4">
                    <h5>Firma Interventor del Contrato</h5>
                    <div class="row" v-if="$store.getters.can('tep.liquidacionesNacionales.firmaInterventor') &&
      !modalData.interventor
      ">
                      <div class="form-group col-md-12">
                        <div class="callout callout-warning">
                          <small><strong>
                              {{
      $store.getters.getAuthAzure
        ? `La sesión esta iniciada con Azure.`
        : `Para firmar la preliquidación por favor digite su clave.`
    }}
                            </strong></small>
                        </div>
                      </div>
                      <div class="form-group col-md-6" v-if="!$store.getters.getAuthAzure">
                        <label for="sign_password">Contraseña</label><input type="password"
                          class="form-control form-control-sm" v-model="formFirmas.interventor.pass" :class="$v.formFirmas.interventor.pass.$invalid
      ? 'is-invalid'
      : 'is-valid'
      " />
                      </div>
                      <div class="form-group col-md-6" v-if="!$store.getters.getAuthAzure">
                        <label for="re_sign_password">Confirme Contraseña</label><input type="password"
                          class="form-control form-control-sm" v-model="formFirmas.interventor.re_pass" :class="$v.formFirmas.interventor.re_pass.$invalid
      ? 'is-invalid'
      : 'is-valid'
      " />
                      </div>
                      <div class="form-group col-md-12">
                        <label for="observaciones">Observación</label><textarea vtype="date" rows="2"
                          class="form-control form-control-sm" v-model="formFirmas.interventor.observacion" :class="$v.formFirmas.interventor.observacion.$invalid
      ? 'is-invalid'
      : 'is-valid'
      "></textarea>
                      </div>
                      <div class="form-group col-md-12">
                        <button type="button" title="Guardar firma" data-toggle="tooltip" class="btn btn-sm bg-success"
                          @click="saveFirma(3)" v-if="$store.getters.can(
      'tep.liquidacionesNacionales.firmaInterventor'
    ) && !$v.formFirmas.interventor.$invalid
      ">
                          <i class="fas fa-signature"></i>
                          <div>
                            <span><small>Aprobar Liquidación</small></span>
                          </div>
                        </button>
                      </div>
                    </div>
                    <div class="row" v-if="!$store.getters.can('tep.liquidacionesNacionales.firmaInterventor') &&
      !modalData.interventor
      ">
                      <div class="form-group col-md-12">
                        <div class="callout callout-warning">
                          <small><strong>
                              A la espera de aprobación del interventor del Contrato.
                            </strong></small>
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="modalData.interventor && modalData.interventor.user">
                      <div class="form-group col-md-12">
                        <p>
                          Firmado por: <br />
                          <strong>{{ modalData.interventor.user.name }}</strong>
                        </p>
                        <p class="text-center mr-5 ml-5" v-if="modalData.interventor.user.link_firma">
                          <img :src="uri_docs + modalData.interventor.user.link_firma
      " class="img-fluid" />
                        </p>
                        <p>
                          Fecha: <br />
                          <strong>{{
      modalData.interventor.fecha_firma
    }}</strong>
                        </p>
                        <p>
                          Observación: <br />
                          {{ modalData.interventor.observacion }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <!--- Firma Transportadora--->
                  <div class="form-group col-md-4">
                    <h5>Firma Representante Transportadora</h5>
                    <div class="row" v-if="$store.getters.can(
      'tep.liquidacionesNacionales.firmaRepresentanteTransportadora'
    ) && !modalData.transportadora
      ">
                      <div class="form-group col-md-12">
                        <div class="callout callout-warning">
                          <small>
                            <strong>Para firmar la liquidación por favor digite su
                              clave.</strong>
                          </small>
                        </div>
                      </div>
                      <div class="form-group col-md-6">
                        <label for="sign_password">Contraseña</label><input type="password"
                          class="form-control form-control-sm" v-model="formFirmas.transportadora.pass" :class="$v.formFirmas.transportadora.pass.$invalid
      ? 'is-invalid'
      : 'is-valid'
      " />
                      </div>
                      <div class="form-group col-md-6">
                        <label for="re_sign_password">Confirme Contraseña</label><input type="password"
                          class="form-control form-control-sm" v-model="formFirmas.transportadora.re_pass" :class="$v.formFirmas.transportadora.re_pass.$invalid
      ? 'is-invalid'
      : 'is-valid'
      " />
                      </div>
                      <div class="form-group col-md-12">
                        <label for="observaciones">Observación</label><textarea vtype="date" rows="2"
                          class="form-control form-control-sm" v-model="formFirmas.transportadora.observacion" :class="$v.formFirmas.transportadora.observacion.$invalid
      ? 'is-invalid'
      : 'is-valid'
      "></textarea>
                      </div>
                      <div class="form-group col-md-12">
                        <button type="button" title="Guardar firma" data-toggle="tooltip" class="btn btn-sm bg-success"
                          @click="saveFirma(2)" v-if="$store.getters.can(
      'tep.liquidacionesNacionales.firmaRepresentanteTransportadora'
    ) && !$v.formFirmas.transportadora.$invalid
      ">
                          <i class="fas fa-signature"></i>
                          <div>
                            <span><small>Aprobar Liquidación</small></span>
                          </div>
                        </button>
                      </div>
                    </div>
                    <div class="row" v-if="!$store.getters.can(
      'tep.liquidacionesNacionales.firmaRepresentanteTransportadora'
    ) && !modalData.transportadora
      ">
                      <div class="form-group col-md-12">
                        <div class="callout callout-warning">
                          <small>
                            <strong>A la espera de aprobación del Administrador del
                              Contrato.</strong>
                          </small>
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="modalData.transportadora &&
      modalData.transportadora.user
      ">
                      <div class="form-group col-md-12">
                        <p>
                          Firmado por: <br />
                          <strong>{{
      modalData.transportadora.user.name
    }}</strong>
                        </p>
                        <p class="text-center mr-5 ml-5" v-if="modalData.transportadora.user.link_firma">
                          <img :src="uri_docs +
      modalData.transportadora.user.link_firma
      " class="img-fluid" />
                        </p>
                        <p>
                          Fecha: <br />
                          <strong>{{
      modalData.transportadora.fecha_firma
    }}</strong>
                        </p>
                        <p>
                          Observación: <br />
                          {{ modalData.transportadora.observacion }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <!--- Firma Administrador Contrato--->
                  <div class="form-group col-md-4">
                    <h5>Firma Administrador Contrato</h5>
                    <div class="row" v-if="$store.getters.can('tep.liquidacionesNacionales.firmaAdministradorContrato') &&
      !modalData.administrador
      ">
                      <div class="form-group col-md-12">
                        <div class="callout callout-warning">
                          <small><strong>
                              {{
      $store.getters.getAuthAzure
        ? `La sesión esta iniciada con Azure.`
        : `Para firmar la preliquidación por favor digite su clave.`
    }}
                            </strong></small>
                        </div>
                      </div>
                      <div class="form-group col-md-6" v-if="!$store.getters.getAuthAzure">
                        <label for="sign_password">Contraseña</label><input type="password"
                          class="form-control form-control-sm" v-model="formFirmas.administrador.pass" :class="$v.formFirmas.administrador.pass.$invalid
      ? 'is-invalid'
      : 'is-valid'
      " />
                      </div>
                      <div class="form-group col-md-6" v-if="!$store.getters.getAuthAzure">
                        <label for="re_sign_password">Confirme Contraseña</label><input type="password"
                          class="form-control form-control-sm" v-model="formFirmas.administrador.re_pass" :class="$v.formFirmas.administrador.re_pass.$invalid
      ? 'is-invalid'
      : 'is-valid'
      " />
                      </div>
                      <div class="form-group col-md-12">
                        <label for="observaciones">Observación</label><textarea vtype="date" rows="2"
                          class="form-control form-control-sm" v-model="formFirmas.administrador.observacion" :class="$v.formFirmas.administrador.observacion.$invalid
      ? 'is-invalid'
      : 'is-valid'
      "></textarea>
                      </div>
                      <div class="form-group col-md-12">
                        <button type="button" title="Guardar firma" data-toggle="tooltip" class="btn btn-sm bg-success"
                          @click="saveFirma(1)" v-if="$store.getters.can('tep.liquidacionesNacionales.firmaAdministradorContrato') &&
      !$v.formFirmas.administrador.$invalid
      ">
                          <i class="fas fa-signature"></i>
                          <div>
                            <span><small>Aprobar Liquidación</small></span>
                          </div>
                        </button>
                      </div>
                    </div>
                    <div class="row" v-if="!$store.getters.can('tep.liquidacionesNacionales.firmaAdministradorContrato') &&
      !modalData.administrador
      ">
                      <div class="form-group col-md-12">
                        <div class="callout callout-warning">
                          <small><strong>
                              A la espera de aprobación de la Transportadora.
                            </strong></small>
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="modalData.administrador && modalData.administrador.user">
                      <div class="form-group col-md-12">
                        <p>
                          Firmado por: <br />
                          <strong>{{
      modalData.administrador.user.name
    }}</strong>
                        </p>
                        <p class="text-center mr-5 ml-5" v-if="modalData.administrador.user.link_firma">
                          <img :src="uri_docs + modalData.administrador.user.link_firma
      " class="img-fluid" />
                        </p>
                        <p>
                          Fecha: <br />
                          <strong>{{
      modalData.administrador.fecha_firma
                            }}</strong>
                        </p>
                        <p>
                          Observación: <br />
                          {{ modalData.administrador.observacion }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer justify-content-between">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal registro de pago liquidación -->
        <div class="modal fade" id="Modal_pago">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header bg-frontera-top-left pt-2 pb-2">
                <h4 class="modal-title text-white">{{ modalTitle }}</h4>
                <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close" id="close-modal"
                  ref="closeModalPago">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="form-group col-md-12">
                    <label>Número de Pago</label><input type="text" class="form-control form-control-sm"
                      v-model="form.n_pago" :class="$v.form.n_pago.$invalid ? 'is-invalid' : 'is-valid'
      " />
                  </div>
                </div>
              </div>
              <div class="modal-footer justify-content-between">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">
                  Close
                </button>
                <button type="button" class="btn btn-primary" @click="savePago()" v-if="$store.getters.can(
      'tep.liquidacionesNacionales.registrarPago'
    ) && !$v.form.$invalid
      ">
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TepLiquidacionPlanilla ref="TepLiquidacionPlanilla" />
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import pagination from "laravel-vue-pagination";
import Loading from "../../../../components/Loading";
import TepLiquidacionPlanilla from "./TepLiquidacionPlanilla";
import { required, sameAs } from "vuelidate/lib/validators";

export default {
  name: "TepLiquidacionesNacionales",
  components: {
    pagination,
    vSelect,
    Loading,
    TepLiquidacionPlanilla,
  },

  data() {
    return {
      cargando: false,
      id: null,
      modalTitle: null,
      modalData: [],
      sitio: {},
      empresa: {},
      contratos: {},
      filtros: {
        empresa_id: null,
        fecha_ini: null,
        fecha_fin: null,
        contrato_id: null
      },
      liquidaciones: {},
      tipos_vehiculos: [],
      operaciones: [],
      areas: [],
      listasForms: {
        estados: [],
        empresas: [],
        sitios: [],
        contratos: [],
        estadosProceso: [],
      },
      form: {
        id: null,
        n_pago: null,
        estado_proceso: 5,
      },
      formFirmas: {
        administrador: {
          id: null,
          tipo_firma: 1,
          tep_liquidacion_nacional_id: null,
          pass: null,
          re_pass: null,
          observacion: null,
        },
        transportadora: {
          id: null,
          tipo_firma: 2,
          tep_liquidacion_nacional_id: null,
          pass: null,
          re_pass: null,
          observacion: null,
        },
        interventor: {
          id: null,
          tipo_firma: 3,
          tep_liquidacion_nacional_id: null,
          pass: null,
          re_pass: null,
          observacion: null,
        },
      },
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },

  validations() {
    let firm_interventor = {};
    let firm_administrador = {};
    if (!this.$store.getters.getAuthAzure) {
      firm_interventor = {
        pass: {
          required,
        },
        re_pass: {
          required,
          sameAsPassword: sameAs("pass"),
        },
        observacion: {
          required,
        },
      };
      firm_administrador = {
        pass: {
          required,
        },
        re_pass: {
          required,
          sameAsPassword: sameAs("pass"),
        },
        observacion: {
          required,
        },
      };
    } else {
      firm_interventor = {
        observacion: {
          required,
        },
      };
      firm_administrador = {
        observacion: {
          required,
        },
      };
    }

    return {
      form: {
        id: {
          required,
        },
        n_pago: {
          required,
        },
        estado_proceso: {
          required,
        },
      },
      formFirmas: {
        transportadora: {
          pass: {
            required,
          },
          re_pass: {
            required,
            sameAsPassword: sameAs("pass"),
          },
          observacion: {
            required,
          },
        },
        interventor: firm_interventor,
        administrador: firm_administrador,

      },
    }
  },

  methods: {
    async getIndex(page = 1) {
      await axios
        .get("/api/tep/liquidacionesNacionales?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.liquidaciones = response.data;
        });
    },

    buscarEmpresas(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/empresas/lista?razon=" + search;
        axios
          .get(url, {
            params: {
              linea_negocio_id: [5],
            },
          })
          .then(function (response) {
            let respuesta = response.data;
            me.listasForms.empresas = respuesta;
            loading(false);
          })
          .catch(function (error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    selectEmpresa() {
      this.filtros.empresa_id = null;
      if (this.empresa) {
        this.filtros.empresa_id = this.empresa.id;
      }
      this.getIndex();
    },

    buscarContratos(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        axios
          .get('api/tep/contratosNacionales/lista?no_contrato=' + search)
          .then(function (response) {
            me.listasForms.contratos = response.data;
            loading(false);
          })
          .catch(function (error) {
            me.$swal({
              icon: "error",
              title: "Ocurrió un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    selectContrato() {
      this.filtros.contrato_id = null;
      if (this.contratos) {
        this.filtros.contrato_id = this.contratos.id;
      }
      this.getIndex();
    },
    /* - */
    async getEstados() {
      await axios.get("/api/lista/124").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    llenar_modal(item) {
      if (this.$store.getters.can("tep.liquidacionesNacionales.index")) {
        this.$refs.TepLiquidacionPlanilla.llenar_modal(item);
      }
    },

    async getEstadosProceso() {
      await axios.get("/api/lista/125").then((response) => {
        this.listasForms.estadosProceso = response.data;
      });
    },

    create() {
      return this.$router.push({
        name: "/Tep/LiquidacionesNacionalesForm",
        params: { accion: "Crear", id: null },
      });
    },

    edit(liqui) {
      return this.$router.push({
        name: "/Tep/LiquidacionesNacionalesForm",
        params: { accion: "Editar", data_edit: liqui, id: liqui.id },
      });
    },

    async printTicket(ticket) {
      let flag = true;
      if (!ticket.fecha_radicacion) {
        await this.$swal({
          title: "Está seguro de generar el Ticket?",
          text: "Los cambios no se pueden revertir!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, Generar!",
        }).then((result) => {
          if (!result.value) {
            flag = false;
          }
        });
      }

      if (flag) {
        this.cargando = true;
        const data = {
          ticket_id: ticket.id,
          fecha_radicacion: ticket.fecha_radicacion,
        };

        axios
          .get(`/api/tep/liquidacionesNacionales/ticket`, {
            params: data,
          })
          .then((response) => {
            let data = response.data;
            this.cargando = false;
            this.getIndex();
            window.open(data.url, "_blank");
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.cargando = false;
          });
      }
    },

    getMsg() {
      if (this.$route.params.msg) {
        this.$swal({
          icon: "success",
          title: this.$route.params.msg,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },

    exportExcel(id) {
      const data = { id };
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/tep/liquidacionesNacionales/excel",
        data,
      })
        .then((response) => {
          let data = response.data;
          location.href = data.url;
          this.cargando = false;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error - " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    getDataFirmas(liqui) {
      this.resetForm();
      this.id = liqui.id;
      this.modalData = [];
      this.modalTitle = `Aprobación de Liquidación`;
      const firmas = liqui.tep_liquidaciones_nacionales_firmas;

      // Se valida si existen firmas
      if (firmas.length > 0) {
        firmas.forEach((firma) => {
          switch (firma.tipo_firma) {
            case 1:
              this.modalData.administrador = firma;
              break;

            case 2:
              this.modalData.transportadora = firma;
              break;

            case 3:
              this.modalData.interventor = firma;
          }
        });
      }
    },

    saveFirma(tipo_firma) {
      const me = this;
      this.cargando = true;
      let form = null;
      switch (tipo_firma) {
        case 1:
          this.formFirmas.administrador.tep_liquidacion_nacional_id = this.id;
          this.formFirmas.administrador.user_id = this.$store.getters.getUser.id;
          form = this.formFirmas.administrador;
          break;
        case 2:
          this.formFirmas.transportadora.tep_liquidacion_nacional_id = this.id;
          this.formFirmas.transportadora.user_id = this.$store.getters.getUser.id;
          form = this.formFirmas.transportadora;
          break;
        case 3:
          this.formFirmas.interventor.tep_liquidacion_nacional_id = this.id;
          this.formFirmas.interventor.user_id = this.$store.getters.getUser.id;
          form = this.formFirmas.interventor;
          break;
      }
      form.tipos_vehiculos = this.tipos_vehiculos;
      form.operaciones = this.operaciones;
      form.areas = this.areas;
      form.loginAzure = this.$store.getters.getAuthAzure;

      axios
        .post("/api/tep/liquidacionesNacionales/firmas", form)
        .then((response) => {
          if (!response.data.user_error) {
            this.cargando = false;
            me.getIndex();
            this.$refs.closeModalFirmas.click();

            me.$swal({
              icon: "success",
              title: "La liquidación fue autorizada exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          } else {
            this.cargando = false;
            me.$swal({
              icon: "error",
              title: response.data.user_error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo... " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    getDataPago(liqui) {
      this.resetForm();
      this.id = liqui.id;
      this.modalTitle = `Registro de Pago de Liquidación - ${this.id}`;
      this.form.id = this.id;
    },

    resetForm() {
      this.tipos_vehiculos = [];
      this.form = {
        id: null,
        n_pago: null,
        estado_proceso: 5,
      };
      this.formFirmas = {
        administrador: {
          id: null,
          tipo_firma: 1,
          tep_liquidacion_nacional_id: null,
          pass: null,
          re_pass: null,
          observacion: null,
        },
        transportadora: {
          id: null,
          tipo_firma: 2,
          tep_liquidacion_nacional_id: null,
          pass: null,
          re_pass: null,
          observacion: null,
        },
        interventor: {
          id: null,
          tipo_firma: 3,
          tep_liquidacion_nacional_id: null,
          pass: null,
          re_pass: null,
          observacion: null,
        },
      };
    },
  },

  async mounted() {
    this.cargando = true;
    await this.getIndex();
    await this.getEstados();
    await this.getEstadosProceso();
    this.getMsg();
    this.cargando = false;
  },
};
</script>
